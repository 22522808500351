/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
@import "./assets/sass/style.angular.scss";


.form-control.ng-touched.ng-invalid {
    border-color: $danger;
}

.ng-touched.ng-invalid>span>span>span.form-select {
    border-color: $danger !important;
}

.header-background {
    // background-color: #17B1AD;
    // z-index: 1;
    background-image: url('./assets/images/default/banner.png');
    height: 30rem;
    background-repeat: no-repeat, repeat;
    background-size: 100% !important;
}

.btn.btn-outline-primary.focus:not(.btn-text),
.btn.btn-outline-primary:focus:not(.btn-text),
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #399ef7;
    background-color: #f4f4f4;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.show .btn.btn-outline-primary.btn-dropdown,
.show>.btn.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #399ef7;
}

.badge-block {
    display: block !important;
}

.badges-purple {
    color: #8950fc;
    background-color: #eee5ff;
}

.badges-purple {
    color: #8950fc;
    background-color: #eee5ff;
}

.badges-warning {
    color: #ef8100;
    background-color: #fff4de;
}

.badge.badge-warning-light {
    background-color: #fff4de !important;
    color: #ef8100;
}

.badge.badge-success-light {
    background-color: #c9f7f5 !important;
    color: #00ab9a;
}

.badge.badge-danger-light {
    background-color: #fef1f1 !important;
    color: #ff4040;
}

.btn.btn-success {
    color: #00ab9a !important;
    background-color: #c9f7f5 !important;
    border-color: #c9f7f5 !important;
}

.btn.btn-success i {
    color: #00ab9a !important;
}

.btn.btn-clear {
    background: transparent !important;
}

.text-capitalized {
    text-transform: capitalize;
}

@-webkit-keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }
}

@keyframes fade-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    -webkit-transition: all 3s ease-out 0s;
    -moz-transition: all 3s ease-out 0s;
    -ms-transition: all 3s ease-out 0s;
    -o-transition: all 3s ease-out 0s;
    transition: all 3s ease-out 0s;
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.animated {
    -webkit-transition: all 3s ease-out 0s;
    -moz-transition: all 3s ease-out 0s;
    -ms-transition: all 3s ease-out 0s;
    -o-transition: all 3s ease-out 0s;
    transition: all 3s ease-out 0s;
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.animated-in {
    -webkit-transition: all 3s ease-out 0s;
    -moz-transition: all 3s ease-out 0s;
    -ms-transition: all 3s ease-out 0s;
    -o-transition: all 3s ease-out 0s;
    transition: all 3s ease-out 0s;
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.animated-out {
    -webkit-transition: all 3s ease-out 0s;
    -moz-transition: all 3s ease-out 0s;
    -ms-transition: all 3s ease-out 0s;
    -o-transition: all 3s ease-out 0s;
    transition: all 3s ease-out 0s;
    -webkit-animation: fade-out-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-out-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.card-cover-img {
    display: block;
    width: 90%;
    height: 250px;
    object-fit: scale-down;
    margin: 1em auto;
    border-radius: 5px;
}

.nowrap-table {
    width: 1% !important;
    min-width: 1% !important;
    white-space: nowrap;
}

.nowrap {
    white-space: nowrap;
}

.bg-muted {
    background-color: #b5b5c3 !important;
}

.text-line-1 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-line-2 {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-line-3 {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-icon>i {
    color: var(--bs-white) !important;
}

.menu-state-bg .menu-item .menu-link.active {
    background-color: rgba(255, 255, 255, 0.3);
}

.drawer.drawer-on {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background: linear-gradient(340deg, rgb(0, 199, 239) 0%, rgb(23, 176, 171) 90%);
    border-right: 1px solid #4fc4e4;
    box-shadow: rgb(0 0 0 / 15%) 2.4px 2.4px 3.2px;
}

.header-menu .menu {
    .menu-item {
        .menu-link {
            .menu-title {
                color: var(--bs-white) !important;
            }

            .menu-icon>i {
                color: var(--bs-white) !important;
            }
        }
    }
}

.menu-sub .menu-item .menu-item {
    .menu-link {
        .menu-title {
            color: var(--kt-text-gray-700) !important;
        }

        .menu-icon>i {
            color: var(--kt-text-gray-700) !important;
        }
    }
}

.drawer.drawer-on {
    .menu {
        .menu-item {
            .menu-link {
                .menu-title {
                    color: var(--bs-white) !important;
                }

                .menu-icon>i {
                    color: var(--kt-text-white) !important;
                }
            }

            .menu-link.active {
                border-top-right-radius: 2rem;
                border-bottom-right-radius: 2rem;
            }
        }
    }
}


.header-fixed[data-kt-sticky-header=on] .header {
    background-color: $primary-color !important;
}

.drawer.drawer-on .menu-state-bullet-primary .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here),
.drawer.drawer-on .menu-state-bullet-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
    background: #6fccd3;
}

@media (min-width: 992px) {
    .header-fixed[data-kt-sticky-header=on] .header {
        height: 75px;
    }
}

.modal-backdrop.show {
    width: 100%;
    height: 100%;
}

.show.menu-dropdown>.menu-sub-lg-dropdown,
.menu-sub-lg-dropdown.menu.show,
.menu-sub-lg-dropdown.show[data-popper-placement] {
    will-change: unset;
}

// DOCUMENT PREVIEW
.ewa-embed-branding-anchor {
    display: none !important;
}

.ewa-embed-buttons {
    display: none !important;
}