//
// Menu
//

// Aside menu
.aside-menu- {
    // Item
    .menu-item {
        padding: 0;

        // Menu title
        .menu-title {
            font-weight: 500;
        }

        // Menu Link
        .menu-link {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }

        // Icon
        .menu-icon {
            justify-content: flex-start;
        }
    }
}